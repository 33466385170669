import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { UserCareerMenu, UserDashboardMenu, HelpChatbot, UpgradeButton } from '@edx/frontend-plugin-learner-dashboard';
import { RecommendationsPanel } from '@edx/frontend-plugin-recommendations';
// Import the Redux store that lives in frontend-app-learner-dashboard
import store from 'data/store';

// List of all regexes for ignoring errors
const ignoredRegexArray = [
  '^Axios Error.*Possible local connectivity issue.',
];

// Join all ignored regexes with a '|'
const IGNORED_ERROR_REGEX = ignoredRegexArray.join('|');

const config = {
  OPTIMIZELY_FULL_STACK_SDK_KEY: 'GiXcfTudkzWv8T29KJgES',
  IGNORED_ERROR_REGEX,
  loggingService: DatadogLoggingService,
  pluginSlots: {
    widget_sidebar_slot: {
      keepDefault: false,
      plugins: [
        {
          // insert the recommendations panel
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'recommendations_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: RecommendationsPanel,
          },
        },
      ],
    },
    header_user_menu_group_item_slot: {
      plugins: [
        {
          // Insert career button in header menu dropdown
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'career_menu_item',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: UserCareerMenu,
          },
        },
      ],
    },
    header_user_menu_group_slot: {
      plugins: [
        {
          // insert personal and enterprise dashboard buttons in header menu dropdown
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'dashboard_user_menu_group',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: UserDashboardMenu,
          },
        },
      ],
    },
    footer_slot: {
      keepDefault: true,
      plugins: [
        {
          // insert the optimizely-experimented chatbot to transition from zendesk to five9
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'optimizely_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: HelpChatbot,
          },
        },
      ],
    },
    course_card_action_slot: {
      keepDefault: false,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'upgrade_button',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: UpgradeButton,
            content: {
              store,
            },
          },
        },
      ],
    },
  },
};

export default config;
